.checkbox-btn {
  user-select: none;
  line-height: 40px;
  font-size: 16px;
  color: #1A1A1A !important;
  position: relative;
  padding-left: 45px;
  display: block;
  cursor: pointer;
  text-align: left;
}

.checkbox-btn input {
  display: none;
}

.checkbox-btn .check-mark {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border: 1px solid #CFB12B;
}

.checkbox-btn .check-mark::after {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  opacity: 1;
}

.checkbox-btn:hover .check-mark::after {
  background-color: #CFB12B;
  opacity: 0.4;
}

.checkbox-btn input:checked ~ .check-mark::after {
  background-color: #CFB12B;
  opacity: 1;
}

.checkbox-btn input:checked ~ .check-mark::before {
  content: "";
  z-index: 10;
  position: absolute;
  top: 6px;
  left: 8.5px;
  display: block;
  transform: rotate(45deg);
  width: 7px;
  height: 10px;
  border: 3px solid #fff;
  border-top: 0;
  border-left: 0;
}

.checkbox-img-btn {
  user-select: none;
  line-height: 40px;
  font-size: 16px;
  color: #1A1A1A !important;
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}