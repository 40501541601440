.step_container {
    width: 110px;
    height: 58px;
    padding: 10px 21px;
    font-size: 12px;
    user-select: none;
}

.step_button {
    text-align: center;
    position: relative;
    width: 68px;
    height: 38px;
}

.step_button img,
.step_button .step_info_header {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.step_button img {
    top: 0;
}

.step_button .step_info_header {
    top: 3px;
    width: 100%;
}

.step_button .step_info,
.step_button .step_info_active {
    position: absolute;
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
}