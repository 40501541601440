.drop-down {
    user-select: none;
    position: relative;
    width: 335px;
    margin: auto;
}

.drop-down label {
    padding-left: 20px;
    font-size: 12px;
    color: rgb(130, 130, 130);
}

.drop-down .selected-item {
    height: 40px;
    line-height: 16px;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #E0E0E0;
    cursor: default;
    position: relative;
}

.drop-down .selected-item img {
    padding: 0 0 3px 10px;
}

.drop-down .drop-down-menu {
    background-color: white;
    width: 100%;
    border: 1px solid #E0E0E0;
    overflow-y: auto;
    position: absolute;
    z-index: 10;
    transition: height 0.3s;
}

.drop-down:not(.active) .drop-down-menu {
    height: 0px;
    border: 0;
}

.drop-down.active .drop-down-menu {
    height: 150px;
    border-top: 0;
}

.drop-down .drop-down-menu::-webkit-scrollbar {
    width: 6px;
}

.drop-down .drop-down-menu::-webkit-scrollbar-thumb {
    padding-right: 4px;
    border-left: 2px solid rgba(207, 177, 43);
    background: transparent;
    height: 48px;
}

.drop-down:not(.active) .selected-item i.fas.fa-angle-down {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    font-size: 20px;
    display: inline-block;
}

.drop-down.active .selected-item i.fas.fa-angle-down {
    display: none;
}

.drop-down.active .selected-item i.fas.fa-angle-up {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    font-size: 20px;
    display: inline-block;
}

.drop-down:not(.active) .selected-item i.fas.fa-angle-up {
    display: none;
}

.drop-down.active .selected-item {
    border-bottom: 0;
}

.drop-down.active .drop-down-menu .drop-down-menu-item:hover,
.drop-down.active .drop-down-menu .drop-down-menu-item.selected {
    background-color: rgba(207, 177, 43, 0.1);
}

.drop-down-menu .drop-down-menu-item {
    padding: 7px 20px;
    height: 34px;
    font-size: 16px;
}

.drop-down-menu .drop-down-menu-item img {
    padding: 0 0 3px 10px;
}