.App {

}

/* @font-face {
    font-family: 'Montserrat';
    src: url('./Styles/Fonts/Montserrat-Regular.ttf');
    font-style: normal;
    font-weight: 400;
} */

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;    
    src: url('https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;    
    src: url('https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Styles/Fonts/Prompt-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Styles/Fonts/Prompt-SemiBold.ttf');
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./Styles/Fonts/SFProDisplay-Medium.eot');
    src: local('./Styles/Fonts/SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('./Styles/Fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Styles/Fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('./Styles/Fonts/SFProDisplay-Medium.woff') format('woff'),
    url('./Styles/Fonts/SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:  url('./Styles/Fonts/icomoon.eot?bc0jlh');
    src:  url('./Styles/Fonts/icomoon.eot?bc0jlh#iefix') format('embedded-opentype'),
    url('./Styles/Fonts/icomoon.ttf?bc0jlh') format('truetype'),
    url('./Styles/Fonts/icomoon.woff?bc0jlh') format('woff'),
    url('./Styles/Fonts/icomoon.svg?bc0jlh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

