button.arrow-btn {
  outline: 0 !important;
  border: 0 !important;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
}

button.arrow-btn:hover:not(.disabled) {
  background-color: rgba(207, 177, 43, 0.1);
}

button.arrow-btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 14px;
  width: 12px;
  height: 12px;
  border: 2px solid #CFB12B;
  border-bottom: 0;
  border-left: 0;
}

button.arrow-btn.right:after {
  left: 11px;
  transform: rotate(45deg);
}

button.arrow-btn.left:after {
  left: 17px;
  transform: rotate(-135deg);
}

button.arrow-btn.disabled {
  cursor: not-allowed;
}

button.arrow-btn.disabled:after {
  border-color: rgba(207, 177, 43, 0.4);
}