.custom-calendar {
    width: 335px;
    height: 318px;
    display: block;
    border: 1px solid #E0E0E0;
    text-align: center;
    margin: auto;
    position: relative;
    user-select: none;
}
.custom-calendar .month {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}
.custom-calendar .arrow-btn {
    position: absolute;
    top: 20px;
}

.custom-calendar .arrow-btn.left {
    left: 20px;
}

.custom-calendar .arrow-btn.right {
    right: 20px;
}

table.days {
    position: absolute;
    top: 76px;
    left: 29px;
    bottom: 26px;
    right: 27px;
    display: inline-block;
    border-spacing: 0 !important;
    width: 278px;
    height: 216px;
}

table.days tbody tr:not(:first-child) {
    border: 0;
    border-collapse: unset;
}

table.days tbody tr td {
    font-size: 16px;
    line-height: 20px;
    width: 38px;
    height: 34px;
    margin-right: 2px;
    display: inline-block;
    border-radius: 2px;
    padding: 7px 8px;
}

table.days tbody tr td:last-child {
    margin-right: 0;
}

table.days tbody tr:first-child {
    border-bottom: 12px solid transparent;
}

table.days tbody tr .day-of-week {
    font-weight: 700;
}

table.days tbody tr .day {
    cursor: pointer;
}

table.days tbody tr .day.today {
    font-weight: 700;
}

table.days tbody tr .day.disabled {
    color: rgba(130, 130, 130, 0.5);
    cursor: not-allowed;
}

table.days tbody tr .day.active {
    font-weight: 700;
    color: white;
    background: #CFB12B;
}

table.days tbody tr .day.empty {
    cursor: default;
}

table.days tbody tr .day:hover:not(.empty):not(.active):not(.disabled) {
    background: rgba(207, 177, 43, 0.1);
}