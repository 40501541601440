#custom_navbar {
  position: relative;
  height: 70px;
  padding: 0;
  background-color: white;
}

#custom_navbar a {
  position: absolute;
  top: 22px;
  left: 60px;
  padding: 0;
  margin: 0;
  line-height: 17px;
}

@media (min-width: 768px) {
  #custom_navbar a img {
    width: 231px;
  }
}

#custom_navbar .navbar_closeBtn {
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
}

@media (max-width: 767px) {
  #custom_navbar {
    height: 60px;
  }
  #custom_navbar a {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  #custom_navbar a img {
    height: 20px;
  }
  
  #custom_navbar .navbar_closeBtn {
    top: 10px;
    right: 15px;
  }
}