.radio-btn {
  user-select: none;
  line-height: 40px;
  font-size: 16px;
  color: #1A1A1A !important;
  position: relative;
  padding-left: 45px;
  display: block;
  cursor: pointer;
}

.radio-btn input {
  display: none;
}

.radio-btn .check-mark {
  position: absolute;
  left: 7px;
  top: 7px;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #CFB12B;
}


.radio-btn .check-mark::after {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 1;
}

.radio-btn:hover .check-mark::after {
  background-color: #CFB12B;
  opacity: 0.4;
}

.radio-btn input:checked ~ .check-mark::after {
  background-color: #CFB12B;
  opacity: 1;
}