body .background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
}

body .background img {
  height: 100%;
}

body .background .leave-modal {
  font-family: 'Montserrat', sans-serif !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: inline-block;
  width: 712px;
  background-color: #fff;
  text-align: center;
}

.leave-modal .leave-modal-header {
  font-size: 26px;
  font-weight: 700;
  line-height: 36.19px;
  margin: 60px auto 17px;
  width: 454px;
}

.leave-modal button {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  outline: 0;
  height: 40px !important;
  width: 453px;
  margin: 0 auto;
}

.leave-modal .leave-btn {
  background-color: #CFB12B;
  margin-bottom: 10px;
  color: white;
}

.leave-modal .leave-btn:active {
  background-color: black !important;
  color: #fff !important;
  border-color: black !important;
}

.leave-modal .leave-btn:hover {
  background-color: black;
  color: #fff !important;
}

.leave-modal .leave-btn:focus {
  box-shadow: none !important;
}

.leave-modal .back-btn {
  background-color: transparent;
  margin-bottom: 60px;
}

.leave-modal .back-btn:active {
  background-color: black !important;
  color: #fff !important;
  border-color: black !important;
}

.leave-modal .back-btn:hover {
  background-color: black;
  color: #fff !important;
}

.leave-modal .back-btn:focus {
  box-shadow: none !important;
}

@media (max-width:767px) {
  body .background .leave-modal {
    max-width: 375px;
    padding: 60px 20px;
  }
  .leave-modal button {
    width: 335px;
    height: 40px !important;
  }
  .leave-modal .leave-modal-header {
    width: 335px;
  }
}