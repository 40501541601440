.form-input {
    position: relative;
    user-select: none;
    width: 335px;
    margin: auto;
}

.form-input .form-control {
    height: 40px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #E0E0E0;
}

.form-input:hover .form-control {
    border-color: #BDBDBD;
}

.form-input .error-msg {
    display: none;
    color: #EB5757;
    margin-top: 5px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 12px;
    height: 15px;
}

.form-input.invalid .error-msg {
    display: block;
}

.form-input.invalid .form-control, .form-input.invalid .form-control:focus {
    color: #EB5757;
    border-color: #EB5757;
}

.form-input .form-control:focus {
    box-shadow: none !important;
    border-color: #828282;
}

.form-input .left-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
}

.form-input .right-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
}

.form-input .right-close-x-btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 35px;
    width: 15px;
    height: 15px;
}

.form-input .right-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-weight: 700;
    color: #CFB12B;
}

.form-input .right-btn:hover {
    color: #1A1A1A;
}

.form-input .form-control:invalid, .form-input .form-control:invalid:focus {
    color: #EB5757;
    border-color: #EB5757;
}